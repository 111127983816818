
import './styles.scss';

var gc_cookielaw = {
    setCookie: function(name, value, days, domain)
    {
        var expires = "";
        var samesite = "; samesite=strict";
        var secure = cookielaw_secure ? "; secure" : "";
        var path = "; path=/";
        domain = typeof domain != 'undefined' ? "; domain=" + domain : '';

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60);
            expires = "; expires=" + date.toUTCString();
        }

        var cookie_string = name + "=" + (value || "") + expires + samesite + secure + domain + path;
        document.cookie = cookie_string;
    },

    eraseCookie: function(name, domain)
    {
        gc_cookielaw.setCookie(name, '', -1, domain);
    },

    eraseCookiesList: function(cookies)
    {
        for (var i = 0; i < cookies.length; i++) {
            if (cookies[i].trim() !== '' && cookies[i] != 'gc_cookielaw') {
                gc_cookielaw.eraseCookie(cookies[i]);
                gc_cookielaw.eraseCookie(cookies[i], location.hostname.replace(/www\./g, ''));
            }
        }
    },

    reloadPageIfPossible: function()
    {
        if (cookielaw_reload == true) {
            location.reload();
        }
    },

    insertJavascript: function(scripts)
    {
        if (!scripts) {
            return;
        }

        scripts.forEach(function(script) {
            var script_element = document.createElement('script');

            script_element.innerHTML = script.script;

            if (script.essential) {
                script_element.classList.add('essential');
            }

            $('body').append(script_element);
        });
    },

    removeJavascript: function()
    {
        $('.consent_scripts').not('.essential').remove();
    },

    getConsentManagerAndInit: function ()
    {
        $.ajax({
            url: cookielaw_ajax_link + '?v=' + (new Date).getTime(),
            type: 'POST',
            cache: false,
            async: true,
            data: {
                ajax: 1,
                action: 'getConsentManager'
            },
            dataType: 'json',
            success: function (data) {
                $('body').prepend(data.consentManager);
                gc_cookielaw.init();
            }
        });
    },

    init: function()
    {
        function toogleSaveAction() {
            if ($("#GcCookieBox .compact-view").length == 0) {
                return;
            }

            if ($(".consent-group-checkbox:checked").length) {
                $(".accept-button-save-wrapper").show();
            } else {
                $(".accept-button-save-wrapper").hide();
            }            
        }

        $(".consent-group-checkbox").on('change', function(e) {
            $(this)
                .closest('.consent-item')
                .find('.consent-checkbox')
                .prop('checked', $(this).prop('checked'));
            $('.consent-group-checkbox[value="' + $(this).val() + '"]').prop('checked', $(this).prop('checked'));
            $('.consent-group-checkbox[value="' + $(this).val() + '"]')
                .closest('.consent-item')
                .find('.consent-checkbox')
                .prop('checked', $(this).prop('checked'));
            $('.accept-button-save').text(cookielaw_txt_savesettings);
            toogleSaveAction();
        });

        $(".consent-checkbox").on('change', function (e) {
            var cg = $(this).data('cg');
            var group = $('.consent-group-checkbox[value="' + cg + '"]').first();
            var modules = $('.consent-group-checkbox[value="' + cg + '"]')
                .closest('.consent-item')
                .find('.consent-checkbox');
            var checkedNumber = 0;
            modules.each(function() { $(this).prop('checked') == true && checkedNumber ++; });
            $('.consent-group-checkbox[value="' + cg + '"]').prop('checked', modules.length == checkedNumber);
            toogleSaveAction();
        });

        $(".expand-consent").on('click', function () {
            var wrapper = $(this).closest('.consent-item');
            if (wrapper.hasClass('closed')) {
                $("#GcCookieBox .consent-item").addClass('closed');
                wrapper.removeClass('closed');
                return;
            }
            wrapper.addClass('closed');
        });

        $(".consent-checkbox").trigger('change');

        $(".accept-button-save-all").on('click', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();

            gc_cookielaw.setCookie('gc_cookielaw', 'all', 1000);

            $("#GcCookieBox").hide();
            $("#GcCookieBoxAlter").show();

            $.ajax({
                url: $(this).attr('href'),
                type: 'POST',
                cache: false,
                async: true,
                data: {
                    ajax: 1,
                },
                dataType: 'json',
                success: function(data) {
                    gc_cookielaw.eraseCookiesList(data.disabledCookies);
                    gc_cookielaw.insertJavascript(data.scripts);
                    gc_cookielaw.reloadPageIfPossible();
                }
            });
        });

        $(".change_settings").on('click', function (e) {
            e.preventDefault();

            gc_cookielaw.eraseCookie('gc_cookielaw');

            $("#GcCookieBoxAlter").hide();
            $("#GcCookieBox").show();

            $.ajax({
                url: $(this).attr('href'),
                type: 'POST',
                cache: false,
                async: true,
                data: {
                    ajax: 1,
                },
                dataType: 'json',
                success: function (data) {
                    gc_cookielaw.eraseCookiesList(data.disabledCookies);
                    gc_cookielaw.removeJavascript();
                }
            });
        });

        $(".accept-button-save").on('click', function (e) {
            e.preventDefault();

            var consent_ids = new Array();
            var cookie_string = '';

            $(".consent-checkbox:checked").each(function () {
                if ($(this).prop('checked')) {
                    consent_ids.push($(this).val());
                }
            });

            cookie_string = consent_ids.join('-');

            if (cookie_string == '') {
                cookie_string = 'essential';
            }

            gc_cookielaw.setCookie('gc_cookielaw', consent_ids.join('-'), 1000);

            $("#GcCookieBox").hide();
            $("#GcCookieBoxAlter").show();

            $.ajax({
                url: $(this).attr('href'),
                type: 'POST',
                cache: false,
                async: true,
                data: {
                    ajax: 1,
                    consent_ids: consent_ids
                },
                dataType: 'json',
                success: function(data) {
                    gc_cookielaw.eraseCookiesList(data.disabledCookies);
                    gc_cookielaw.insertJavascript(data.scripts);
                    gc_cookielaw.reloadPageIfPossible();
                }
            });
        });

        $('a[data-cookie-settings]').on('click', function(e) {
            $(".cookie-box-simple").hide();
            $("#GcCookieBox .wrapper").addClass('advanced');
            $(".cookie-box-advanced").show();
            e.preventDefault();
        });

        $(".back-link a").on('click', function(e) {
            $(".cookie-box-simple").show();
            $(".cookie-box-advanced").hide();
            $("#GcCookieBox .wrapper").removeClass('advanced');
            e.preventDefault();
        });

        $("#GcCookieBox .wrapper").on('click', function (e) {
            e.stopPropagation();
        });

        $("#GcCookieBox .close-me, #GcCookieBox .close").on('click', function (e) {
            $("#GcCookieBox .wrapper").addClass('rotateOutDownRight');
            $("#cookie-box-outer").fadeOut();
            setTimeout(function() {
                $("#GcCookieBox").hide();
                $("#GcCookieBoxAlter").show();

                $("#GcCookieBox .wrapper").removeClass('rotateOutDownRight');
                $("#cookie-box-outer").show();
            }, 800);
        });

        if($('#cookiebanner').length >= 1) {
            var footerPad = parseInt($('#footer').css('paddingBottom'));
            var form = $('#cookiebanner');

            if(!($('#cookiebanner').hasClass('guideline'))) {
                var cookiebannerHeight = parseInt($('#cookiebanner .container').outerHeight());
                $('#footer').css('paddingBottom', (footerPad + cookiebannerHeight) + 'px');
            }

            $('#cookiebanner .background').on('click', function(e) {
                e.preventDefault();

                form.removeClass('guideline');
                $('button[name=submitCookielawDeny]').parent().remove();
            });

            $('#cookiebanner button').on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();

                var action = $(this).attr('name');

                if (action == 'submitCookielawDeny') {
                    form.removeClass('guideline');
                    $('button[name=submitCookielawDeny]').parent().remove();
                    form.remove();
                    $('#footer').css('paddingBottom', '10px');
                } else if (action == 'submitCookielaw') {
                    form.hide();

                    $.getJSON(
                        form.attr('action'),
                        {
                            'ajax': 1,
                            'action': 'submitCookielaw',
                            'data':form.serialize(),
                            'dataType': 'json'
                        },
                        function(result) {
                            if(result == 'ok') {
                                form.remove();
                                if($(this).hasClass('guideline')) {
                                    $('#footer').css('paddingBottom', footerPad + 'px');
                                }
                            } else {
                                form.show();
                            }
                        }
                    );
                }
            });
            $('#cookiebanner .consent-preferences a').on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
                $('#cookiebanner .consent').toggle();
            });
        }
    }
}

$(document).ready(function() {
    if (cookielaw_force_delete_cookie) {
        gc_cookielaw.eraseCookie('gc_cookielaw');
    }

    if (cookielaw_display_consent_by_js) {
        gc_cookielaw.getConsentManagerAndInit();
    } else {
        gc_cookielaw.init();
    }
});
